@use "@angular/material" as mat;
@use 'ngx-sharebuttons/themes/default' as sharebuttons;

@include mat.elevation-classes();
@include mat.app-background();

@import "@ag-grid-community/styles/ag-grid.css";
@import "@ag-grid-community/styles/ag-theme-alpine.css";

@layer tailwind-base, primeng, tailwind-utilities, media;

@layer tailwind-base {
  @tailwind base;
}

@layer primeng; //not necessary I think

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
  @tailwind variants;

  a {
    text-decoration: none;
    color: inherit;
  }
}
@import "primeicons/primeicons.css";

/* You can add global styles to this file, and also import other style files */

.ag-theme-alpine {
  --ag-font-size: 12px;
  --ag-grid-size: 6px;
  --ag-cell-horizontal-padding: 8px;
}

$my-custom-typography-config: mat.m2-define-typography-config(
  $button:
    mat.m2-define-typography-level(
      $font-family: Roboto,
      $font-weight: 500,
      $font-size: 0.8rem,
      $line-height: 1,
      $letter-spacing: normal,
      // Set your desired letter spacing here
    )
);

$my-custom-level: mat.m2-define-typography-level(
  $font-family: Roboto,
  $font-weight: 500,
  $font-size: 1rem,
  $line-height: 1,
  $letter-spacing: normal
);

$light-primary: mat.m2-define-palette(mat.$m2-blue-gray-palette);
$dark-accent: mat.m2-define-palette(mat.$m2-orange-palette);

/*$dark-theme: mat.define-dark-theme(
    (
      color: (
        primary: $dark-primary,
        accent: $dark-accent,
      ),
      density: -1,
      typography: $my-custom-typography-config,
    )
);*/

$light-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $light-primary,
      accent: $dark-accent
    ),
    density: -1,
    typography: $my-custom-typography-config
  )
);

/*
.darker-theme {
  @include mat.core-theme($dark-theme);
  @include mat.button-theme($dark-theme);
  @include mat.tabs-theme($dark-theme);
  @include mat.input-theme($dark-theme);
 // @include mat.all-component-themes($dark-theme);
}
*/

.lighter-theme {
  @include mat.elevation-classes();
  @include mat.app-background();
  @include mat.button-theme($light-theme);
  @include mat.tabs-theme($light-theme);
  @include mat.select-theme($light-theme);
  @include mat.form-field-theme($light-theme);
  @include mat.input-theme($light-theme);
  @include mat.button-toggle-theme($light-theme);
  @include mat.slide-toggle-theme($light-theme);
  @include mat.progress-spinner-theme($light-theme);
  @include mat.progress-bar-theme($light-theme);
  @include mat.checkbox-theme($light-theme);
  @include mat.datepicker-theme($light-theme);
  @include mat.menu-theme($light-theme);
  @include mat.toolbar-theme($light-theme);
  @include mat.list-theme($light-theme);
  @include mat.icon-theme($light-theme);
  @include mat.icon-button-theme($light-theme);
  @include mat.expansion-theme($light-theme);
  @include mat.option-theme($light-theme);
  @include mat.tooltip-theme($light-theme);
  @include mat.card-theme($light-theme);
  @include mat.fab-theme($light-theme);
  @include mat.snack-bar-theme($light-theme);
  @include mat.radio-theme($light-theme);
  @include mat.stepper-theme($light-theme);
  @include mat.chips-theme($light-theme);

   @include mat.all-component-themes($light-theme);
}

:root {
  --light-text: rgba(255, 255, 255, 0.815);
}
.mat-button-toggle-appearance-standard {
  letter-spacing: normal !important;
}
.light-text {
  color: var(--light-text);
}

$color-athlete-light: #28b4b1;
$color-athlete-regular: #4eab8e;
$color-athlete-dark: #097663;
$color-club-light: #4BB1D4;
$color-club-regular: #398FCE;
$color-club-dark: #3f51b5;
$color-event-light: #f8cb56;
$color-event-regular: #c67349;
$color-event-dark: #9e4b2f;
$color-material-red: #f44336;
$color-material-pink: #ff4081;

$color-material-primary-500: #607d8b;
$color-material-primary-600: #546e7a;
$color-material-primary-700: #455a64;

$font-dark-gray: rgb(56, 56, 56);
$font-dark-gray-lighter: rgba(56, 56, 56, 0.438);
$font-dark-grayer: rgba(42, 42, 42, 0.58);

html,
body {
  display: block;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: white;
}



.h-screen-header {
  height: calc(100vh - 64px);
}

.mdc-notched-outline__notch {
  border-right: none;
}

.mat-card-box-shadow {
  box-shadow: var(--mdc-elevated-card-container-elevation);
}

.mat-mdc-dialog-surface {
  padding: 24px;
}

.mat-dialog-overflow-visible {
  overflow: visible;

  .mat-mdc-dialog-surface {
    overflow: visible;
  }
}

.mat-tab-overflow-visible {
  .mat-tab-body-wrapper {
    overflow: visible;

    .mat-tab-body-active {
      overflow-x: visible;
      overflow-y: visible;

      .mat-tab-body-content {
        overflow: visible;
      }
    }
  }
}

.cdk-drag-and-drop-container {
  .cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow:
      0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
  .cdk-drag-placeholder {
    opacity: 0.5;
  }
  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
  .drop-list.cdk-drop-list-dragging .drop-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
}

.text-shadow-sm {
  text-shadow: 0 1px 2px black;
}

.athlete-color-spinner {
  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: $color-athlete-regular;
  }
}

.event-color-spinner {
  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: $color-event-regular;
  }
}

.event-color-bg {
  background: linear-gradient(90deg, $color-event-regular 0%, $color-event-light 100%);
}

.lighter-theme .event-round-toggle .mat-button-toggle-checked {
  background-color: #607d8b;
  color: white;
}

.dialog-overflow-visible {
  .mat-dialog-container {
    overflow: visible;
  }
}

.admin-card {
  background-color: theme("colors.white");
  border-radius: theme("borderRadius.lg");
  padding: theme("spacing.6");
  box-shadow: theme("boxShadow.xl");
}

.ag-grid-clickable-cell {
  cursor: pointer;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
}

.prime-selector {
  // => force beathletics-prime selectors to take 100% of container width
  // + keep their original height & set label color to black
  .p-dropdown {
    width: 100%;
    height: 50px;

    .p-dropdown-label {
      color: black;
    }
  }

  .p-multiselect {
    width: 100%;
    height: 50px;

    .p-multiselect-label {
      color: black;
    }
  }

  .p-treeselect {
    width: 100%;
    height: 50px;
  }
}

.p-panel-calendar {
  .prime-selector {
    .p-multiselect {
      .p-multiselect-panel {
        max-width: 14rem;
      }
    }
    .p-float-label {
      label {
        color: black;
      }
    }
  }
}

.athlete-theme {
  /* mat-form-field theming */
  .mat-focused .mat-form-field-label {
    /* change color of label */
    color: $color-athlete-dark !important;
  }

  .mat-select-arrow {
    /* change color of arrow */
    color: $color-athlete-dark !important;
  }

  .mat-form-field-underline {
    /* change color of underline */
    background-color: $color-athlete-dark !important;
  }

  .mat-form-field-ripple {
    /* change color of underline when focused */
    background-color: $color-athlete-dark !important;
  }
}

.tabs-container {
  .mdc-tab__text-label,
  .mat-mdc-tab-header-pagination-chevron,
  .mdc-tab-indicator__content--underline {
    color: white !important;
    border-color: white !important;
  }
}

.event-theme {
  .mat-tab-header {
    background-color: $color-event-regular !important;
  }
}

.bg-color {
  &.athlete {
    background-color: $color-athlete-regular !important;
  }
  &.athlete-dark {
    background-color: $color-athlete-dark !important;
  }

  &.club {
    background-color: $color-club-regular !important;
  }
  &.club-dark {
    background-color: $color-club-dark !important;
  }

  &.event {
    background-color: $color-event-regular !important;
  }
  &.event-dark {
    background-color: $color-event-dark !important;
  }

  &.material {
    background-color: $color-material-primary-500 !important;
  }
}

.borders {
  &.athlete {
    border-color: $color-athlete-regular !important;
  }
  &.club {
    border-color: $color-club-regular !important;
  }
  &.event {
    border-color: $color-event-regular !important;
  }
  &.material {
    border-color: $color-club-dark !important;
  }
}

.font-color {
  &.athlete,
  &.club,
  &.calendar {
    &.header-title {
      color: rgba(255, 255, 255, 0.603);
      background-color: transparent;

      a.text-lighter,
      a:hover,
      mat-icon:hover {
        color: white;
      }
    }

    color: $font-dark-gray-lighter;
    background-color: transparent;

    a.text-lighter,
    a:hover,
    mat-icon:hover {
      color: $font-dark-gray;
    }

    &.infos-text {
      color: white;
    }

    button,
    ::placeholder {
      color: white;
    }
  }

  &.event {
    color: rgba(255, 255, 255, 0.603);
    background-color: transparent;

    a.text-lighter,
    a:hover,
    mat-icon:hover {
      color: white;
    }

    &.infos-text {
      color: white;
    }

    &.colored {
      color: $color-event-dark;
    }
  }

  &.material {
    beathletics-public-search-bar {
      input {
        background-color: #e1e1e16d;
      }

      button,
      ::placeholder {
        color: black;
      }
    }

    &.infos-text {
      color: $font-dark-gray;
    }

    color: $font-dark-grayer;
    background-color: transparent;

    a.text-lighter,
    a:hover,
    mat-icon:hover {
      color: $font-dark-gray;
    }
  }
}

.responsive-dialog {
  @apply w-screen h-screen sm:max-h-[90%] min-w-[320px] sm:min-w-[500px] sm:w-fit sm:h-fit;
  overflow: hidden !important;
  padding: 0 !important;
  max-width: 100vw !important;

  .mat-dialog-container {
    display: flex;
    flex-direction: column;
    @media (max-width: 640px) {
      border-radius: 0 !important;
    }

    .mat-dialog-content {
      max-height: initial;
    }
  }
}

.discipline-overflow {
  display: inline-flex;
  align-items: center;
}

.discipline-overflow .mat-checkbox-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  //  max-width: ; __<<ngM2ThemingMigrationEscapedComment8>>__
}

// => set mat-select error color to red even when disabled (to use mat-option as error message)
.mat-mdc-option.mdc-list-item--disabled.error .mdc-list-item__primary-text {
  color: red;
  opacity: 1;
}

// => fix mat-autocomplete background color from being transparent
:root {
  --mat-autocomplete-background-color: white;
}
